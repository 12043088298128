<template>
    <div class="chat" id="chatbox">
        <h2 class="no_chat" v-if="!chatLogs.length">
            <img src="@/assets/images/empty_chat.svg">
            You haven't started any chat with this contact yet.
        </h2>
        <infinite-loading v-if="initInfiniteLoader" class="infinite-loading" @infinite="handleChatLogs" target=".chat" :identifier="resetInfiniteWatcher" :top="true" />
        <div v-for="(chatLog, c) in chatLogs.slice().reverse()" :key="c" >
            <div v-html="getDaylabel(chatLog.date_sent, c)"></div>
            <div class="chat_client" v-if="chatLog.direction == 'outbound'">
                <span class="option">
                    <i class="fas fa-ellipsis-v"></i>
                    <ul>
                        <li><label>Created :</label><span>{{ moment.utc(chatLog.date_sent).local().format('ll | LT') }}</span></li>
                        <li><label>Sent :</label><span>{{ moment.utc(chatLog.date_sent).local().format('ll | LT') }}</span></li>
                        <li><label>Delivered :</label><span>{{ moment.utc(chatLog.date_sent).local().format('ll | LT') }}</span></li>
                    </ul>
                </span>
                <div class="msg">
                    <p :style="{ 'white-space': 'pre-line' }">{{ chatLog.body }}</p>
                    <div v-if="chatLog.media">
                        <div class="media_view" v-for="(image, m) in chatLog.media" :key="m" v-html="getFileIconByUrl(image.url ? image.url : image, uniqueId, image.type ? image.type : '' )"></div>
                    </div>
                    <span v-if="(chatLog.message_status == 'undelivered' || chatLog.message_status == 'failed')" class="text-danger pointer" @click="resendFailedMessage(chatLog)">This message failed to send. Click to send again</span>
                    <span>{{ moment.utc(chatLog.date_sent).local().fromNow() }}</span>
                </div>
                <img :src="chatLog.employer && chatLog.employer.profile_pic ? chatLog.employer.profile_pic : '@/assets/images/logo.png'" @error="replaceByDefault">
            </div>
            <div class="chat_user" v-else>
                <img :src="contact.photo" alt="">
                <div class="msg">
                    <p :style="{ 'white-space': 'pre-line' }">{{ chatLog.body }}</p>
                    <div v-if="chatLog.media">
                        <div v-for="(image, m) in chatLog.media" :key="m" v-html="getFileIconByUrl(image.url ? image.url : image, uniqueId, image.type ? image.type : '' )"></div>
                    </div>
                    <span>{{ moment.utc(chatLog.date_sent).local().fromNow() }}</span>
                </div>
                <span class="option">
                    <i class="fas fa-ellipsis-v"></i>
                    <ul>
                        <li><label>Created :</label><span>{{ moment.utc(chatLog.date_sent).local().format('ll | LT') }}</span></li>
                        <li><label>Sent :</label><span>{{ moment.utc(chatLog.date_sent).local().format('ll | LT') }}</span></li>
                        <li><label>Delivered :</label><span>{{ moment.utc(chatLog.date_sent).local().format('ll | LT') }}</span></li>
                    </ul>
                </span>
            </div>
        </div>
    </div>
    <div class="chat_box">
        <!-- <button type="button" @click="handleCreateSchedule" class="schedule_btn hideOnTinyScreens">
            <img src="@/assets/images/calendar-alt.svg" alt="">
        </button> -->
        <div class="input_area" :class="{ 'input_area_error' : countError }">
            <textarea rows="10" v-model="sms" placeholder="Type Your Message.." id="sms-textbox" maxlength="320" @click="handleMarkRead()"></textarea>
            <button type="button" @click="attachment = true" class="hideOnTinyScreens">
                <i class="fas fa-paperclip"></i>
            </button>
            <div class="hideOnTinyScreens dropdown personalize" @click="personalizeOption = !personalizeOption" v-click-outside="closePersonalizeOption">
                <i class="fas fa-user"></i>
                <div class="drp_wrapper" v-if="personalizeOption" :class="{ active : personalizeOption }">
                    <ul>
                        <li v-for="(personalize, p) of defaultPersonalize" @click="insertPersonalize(personalize)" :key="p">{{ personalize }}</li>
                    </ul>
                </div>
            </div>
            <emoji-picker :onSelectEmoji="insertEmoji" classes="hideOnTinyScreens chat-emoji-picker" />
            <button type="button" class="hideOnTinyScreens post" @click="handleSendSMS">
                <i class="fas fa-spin fa-spinner" v-if="smsLoader"></i>
                <i class="fas fa-paper-plane" v-else></i>
            </button>
            <button type="button" @click="handleCreateSchedule" class="hideOnTinyScreens">
                <img src="@/assets/images/calendar-alt.svg" alt="">
            </button>
            <span class="hideOnTinyScreens counter">{{sms.length}} | 320</span>
            <span class="hideOnTinyScreens media_area mt-1" v-show="media">
                <label>{{ mediaFile.name }} ({{ bytesToSize(mediaFile.size) }})</label> <i class="fa fa-times pointer text-danger" @click="media = ''"></i>
            </span>
        </div>
    </div>
    <div class="chat_box onTinyScreen">
      <button type="button" @click="handleCreateSchedule" class="schedule_btn">
        <img src="@/assets/images/calendar-alt.svg" alt="">
      </button>
      <span class="media_area mt-1" v-show="media">
                  <label>{{ mediaFile.name }} ({{ bytesToSize(mediaFile.size) }})</label> <i
          class="fa fa-times pointer text-danger" @click="media = ''"></i>
              </span>
      <div class="input_area" :class="{ 'input_area_error' : countError }">
        <button type="button" @click="attachment = true">
          <i class="fas fa-paperclip"></i>
        </button>
        <div class="dropdown" @click="personalizeOption = !personalizeOption">
          <i class="fas fa-user"></i>
          <div class="drp_wrapper" v-if="personalizeOption" :class="{ active : personalizeOption }">
            <ul>
              <li v-for="(personalize, p) of defaultPersonalize" @click="insertPersonalize(personalize)" :key="p">
                {{ personalize }}
              </li>
            </ul>
          </div>
        </div>
        <emoji-picker :onSelectEmoji="insertEmoji" classes="chat-emoji-picker"/>
        <button type="button" class="post" @click="handleSendSMS">
          <i class="fas fa-spin fa-spinner" v-if="smsLoader"></i>
          <i class="fas fa-paper-plane" v-else></i>
        </button>
      </div>
    </div>
    <div class="modal" v-if="attachment" :class="{ active : attachment }" @click.self="attachment = false">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Attach Media</h1>
                </div>
                <button class="close_btn left_out" @click="attachment = false"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="setting_wpr">
                    <div class="upload_image">
                        <div :id="`dz-${uniqueId}`" class="dropzone" :class="{ 'dropzone-error' : fileError }">
                            <div class="dz-message" data-dz-message>
                                <div>
                                    <img src="@/assets/images/image2.png">
                                    <h4>Click to Upload Content</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal_footer">
                <button type="button" class="btn cancel_btn" @click="attachment = false; media = '';">Cancel</button>
                <button type="button" class="btn save_btn" @click="attachment = false">Attach</button>
            </div>
        </div>
    </div>
    <create-schedule v-model="createSchedule" :contact-id="contact.id" :sms="sms" :media="media" />
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import { uuid } from 'vue-uuid'
    import { defineAsyncComponent } from 'vue'

    const CreateSchedule = defineAsyncComponent(() => import('@/pages/sms-center/CreateSchedule'))
    const EmojiPicker = defineAsyncComponent(() => import('@/components/EmojiPicker'))

    import InfiniteLoading from 'v3-infinite-loading'
    import moment from 'moment'
    import Helper from '@/utils/Helper'
    import Dropzone from 'dropzone'
    import 'dropzone/dist/dropzone.css'
    import constants from '@/utils/Constant'

    export default {
        name: 'Chat Box',

        data () {
            return {
                personalizeOption: false,
                attachment: false,
                params: {
                    per_page: 10,
                    page: 1,
                    last_page: 1,
                    search: '',
                    filter: '',
                    id: ''
                },
                state: undefined,
                loader: false,
                chatLogs: [],
                resetInfiniteWatcher: 0,
                moment,
                uniqueId: 'id'+uuid.v4(),
                dayCheck: '',
                sms: '',
                media: '',
                mediaFile: '',
                contact_id: '',
                source: 'sms chat',
                countError: false,
                fileError: false,
                form: {
                    tags_id: [],
                    contacts: [],
                    action: '/contacts/add-tag',
                    type: 'leads'
                },
                createSchedule: false,
                defaultPersonalize: [],
                initInfiniteLoader: false,
                markReadCount: 0,
            }
        },

        props: {
            contact: Object,
            filter: {
                type: String,
                default: '',
            },
            markRead: {
                type: Function,
                default: () => {},
            }
        },

        watch: {
            contact (contact) {
                const vm = this;

                vm.resetForm();
            },

            sms (val) {
                const vm = this;

                vm.countError = val.length > 320 ? true : false;
            },

            attachment (attachment) {
                const vm = this;

                vm.fileError = false;

                if (attachment) {
                    setTimeout(function () {
                        vm.initDropzone();
                    }, 100);
                }
            }
        },

        components: {
            EmojiPicker,
            InfiniteLoading,
            CreateSchedule,
        },

        computed: mapState ({
            user: state => state.authModule.user,
            authToken: state => state.authModule.authToken,
            smsLoader: state => state.contactModule.smsLoader,
        }),

        mounted () {
            const vm = this;

            vm.defaultPersonalize = constants.personalize.concat(vm.personalize);

            if (vm.contact.id) {
                vm.resetForm();
            }

            document.getElementById('chatbox').addEventListener('scroll', event => {
                if (event.target.scrollTop == 0 && !vm.loader) {
                    vm.initInfiniteLoader = true;
                }
            });
        },

        methods: {
            ...mapActions({
                getChatLogs: 'smsCenterModule/getChatLogs',
                sendSMS: 'contactModule/sendSMS',
                resendSMS: 'smsCenterModule/resendSMS',
            }),

            insertEmoji (emoji) {
                const vm = this;
                const textarea    = document.getElementById('sms-textbox');
                const caretPos    = textarea.selectionStart;
                const textAreaTxt = textarea.value;
                const txtToAdd    = emoji;

                vm.sms = textAreaTxt.substring(0, caretPos) + ' ' +  txtToAdd + ' '  + textAreaTxt.substring(caretPos);
            },

            insertPersonalize (personalize) {
                const vm = this;
                const textarea    = document.getElementById('sms-textbox');
                const caretPos    = textarea.selectionStart;
                const textAreaTxt = textarea.value;
                const txtToAdd    = `{{ ${ personalize.toLowerCase().replaceAll(' ', '_') } }}`;

                vm.sms = textAreaTxt.substring(0, caretPos) + ' ' +  txtToAdd + ' '  + textAreaTxt.substring(caretPos);
            },

            handleChatLogs ($state, force = false) {
                const vm = this;

                if ($state) {
                    vm.state = $state;
                }

                if ((vm.params.page <= vm.params.last_page ) || force) {
                    if (!vm.loader) {
                        vm.loader = true;

                        vm.getChatLogs(vm.params).then((result) => {
                            if (result.data.length) {
                                vm.params.last_page = result.last_page;
                                vm.params.page      = result.current_page + 1;
                                vm.chatLogs.push(...result.data);
                                vm.loader = false;

                                if (vm.state != undefined) {
                                    vm.state.loaded();
                                }

                                if (vm.chatLogs.length == result.data.length) {
                                    setTimeout(function () {
                                        const chatbox      = document.getElementById('chatbox');
                                        chatbox.scrollTop  = chatbox.scrollHeight;
                                    }, 100);
                                }
                            } else {
                                if (vm.state != undefined) {
                                    vm.state.complete();
                                }

                                vm.loader = false;
                            }
                        });
                    }
                } else {
                    if (vm.state != undefined) {
                        vm.state.complete();
                    }
                }
            },

            resetForm () {
                const vm = this;
                vm.initInfiniteLoader = false;

                vm.chatLogs = [];
                vm.params = {
                    per_page: 10,
                    page: 1,
                    last_page: 1,
                    search: '',
                    filter: vm.filter,
                    id: vm.contact.id
                };

                vm.sms  = '';
                vm.media = '';
                vm.contact_id = vm.contact.id;
                vm.resetInfiniteWatcher++;
                vm.markReadCount = 0;

                setTimeout(function () {
                    vm.handleChatLogs(undefined, true);
                }, 10);

            },

            getFileIconByUrl (image, uniqueId, contentType = '') {
                return Helper.getFileIconByUrl(image, uniqueId, contentType);
            },

            getDaylabel (date, key) {
                const vm    = this;
                const day   = moment.utc(date).local().format('ll');
                const today = moment().local().format('ll');

                if (key == 0 || vm.daycheck != day) {
                    vm.daycheck = day;

                    if (vm.daycheck == today) {
                        return `<div class="timeline"><span>Today</span></div>`;
                    } else if (vm.daycheck == (today - 1)) {
                        return `<div class="timeline"><span>Yesterday</span></div>`;
                    } else {
                        return `<div class="timeline"><span>${vm.daycheck}</span></div>`;
                    }
                } else {
                    return void(0);
                }
            },

            handleSendSMS() {
                const vm = this;

                if (vm.sms.length == 0 || vm.sms.length > 320) {
                    return vm.countError = true;
                }

                const params = {
                    sms: vm.sms,
                    media: vm.media ? [vm.media] : [],
                    contact_id: vm.contact.id,
                    source: 'sms chat',
                }

                vm.sendSMS(params).then((result) => {
                    // vm.resetForm();
                    vm.sms  = '';
                    vm.media = '';

                    if (result) {
                        const log = {
                            employer: { profile_pic: vm.user ? vm.user.profile_pic : '' },
                            body: params.sms,
                            media: params.media,
                            contact_id: vm.contact.id,
                            employer_id: vm.user ? vm.user.id : 0,
                            direction: 'outbound',
                            message_status: 'outbound',
                            created_at: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                            date_sent: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                            from: '',
                            id: 0,
                            is_delete: 0,
                            is_unread: 0,
                            sid: '',
                            to: '',
                            updated_at: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                        };

                        vm.chatLogs.unshift(log);

                        setTimeout(function () {
                            const chatbox = document.getElementById('chatbox');

                            if (chatbox) {
                                chatbox.scrollTop  = chatbox.scrollHeight;
                            }
                        }, 100);
                    }
                });
            },

            initDropzone () {
                const vm = this;

                const dropzone = new Dropzone(`#dz-${vm.uniqueId}`, {
                    url: process.env.VUE_APP_API_URL+'/api/v3/content-setting/file-upload',
                    autoProcessQueue: true,
                    maxFiles: 1,
                    acceptedFiles: '.jpg, .jpeg, .png, .mp3, .mp4, .pdf',
                    maxFilesize: 4.5,
                    paramName: 'file[]',
                    createImageThumbnails: true,
                    addRemoveLinks: true,
                    headers: {
                        'Authorization': 'Bearer '+vm.authToken.access_token,
                        'accessed-location-id': vm.authToken.accessed_location_id,
                        'company-admin-id': vm.authToken.company_admin_id,
                    },

                    init: function () {
                        this.on("success", function(file, response) {
                            vm.mediaFile  = file;
                            vm.media      = response.url;
                            vm.fileError  = false;
                        });

                        this.on("error", function(file, errorMessage) {
                            vm.fileError = true;
                        });
                    },

                    removedfile: function (file) {
                        vm.media = '';
                        var ref  = file.previewElement;
                        return ref != null ? ref.parentNode.removeChild(file.previewElement) : void 0;
                    },
                })
            },

            handleCreateSchedule () {
                const vm = this;
                if (!vm.sms.length || vm.sms.length > 320) {
                    vm.countError = true;
                } else {
                    vm.createSchedule = true;
                }
            },

            bytesToSize (bytes) {
                const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

                if (bytes == 0) {
                    return '0 Byte';
                }

                const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));

                return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
            },

            resendFailedMessage (sms) {
                const vm = this;

                const params = {
                    contact_id: sms.contact_id,
                    sid: sms.sid,
                    message: sms.body,
                    media: sms.media,
                    source: 'sms chat',
                };

                vm.resendSMS(params).then((result) => {
                    if (result) {
                        vm.updateSMSStatus(sms.sid);
                    }
                });
            },

            updateSMSStatus(sid) {
                const vm = this;

                for (let i = 0; i < vm.chatLogs.length; i++) {
                    if (vm.chatLogs[i].sid === sid) {
                        vm.chatLogs[i].message_status = 'resend';
                    }
                }
            },

            handleMarkRead () {
                const vm = this;

                if (vm.filter == 'unread' && !vm.markReadCount) {
                    vm.markReadCount++;
                    vm.markRead(0);
                }
            },

            closePersonalizeOption(){
                const vm = this;
                vm.personalizeOption = false;
            },
        }
    }
</script>

<style scoped>
    .onTinyScreen {
      display: none;
    }
    @media screen and (max-width: 399px) {
      /* .onTinyScreen {
        display: flex;
      } */
      /* .hideOnTinyScreens {
        display: none;
      } */
      .chat_box.onTinyScreen .input_area {
        justify-content: right;
      }
      .chat_box.onTinyScreen .media_area label {
        font-size: 11px;
        line-height: 14px;
        color: #2f7fed;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
    :deep(.chat-emoji-picker .v3-input-picker-wrap) {
        width: 40px;
        height: 40px;
    }

    :deep(.chat-emoji-picker .v3-input-picker-wrap .v3-input-picker-icon) {
        width: 28px;
        height: 18px;
    }

    .chat_box .input_area_error {
        border: 1px solid #eb1414 !important;
    }

    h2.no_chat{
        font-size: 18px;
        line-height: 27px;
        width: 350px;
        text-align: center;
        color: #999;
        font-weight: 300;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    h2.no_chat img{
        display: block;
        max-width: 120px;
        height: auto;
        margin: 0 auto 20px auto;
    }
    .dropzone {
        border: 0;
        background: #FAFCFD;
        min-height: 150px;
    }

    :deep(.dz-message) {
        vertical-align: middle;
        margin: auto;
        display: grid;
        height: 100%;
        min-height: 150px;
    }

    :deep(.dz-message div) {
        margin: auto;
    }

    :deep(.dropzone .dz-preview .dz-image) {
        border-radius: 20px;
        overflow: hidden;
        width: 100%;
        height: 100%;
        position: relative;
        display: block;
        z-index: 10;
    }

    .file-error {
        float: left;
        color: #eb1414;
    }

    .dropzone-error {
        border: 1.2px dashed #eb1414;
    }
    .chat_box .dropdown.personalize{
        z-index: 2;
    }
    .chat_box .dropdown .drp_wrapper{
        left: 25px;
        bottom: 0;
        right: auto;
        margin: 0;
        text-align: left;
    }

    .chat_box .input_area .media_area {
        position: absolute;
        right: 0;
        top: 100%;
        margin-top: 5px;
        background-color: #f0f0f0;
        border: 1px solid transparent;
        font-weight: bold;
        margin: 0 0 9px;
        padding: 4px 25px 4px 8px;
        border-radius: 11px;
        text-align: left;
    }
    .chat_box .input_area .media_area label{
        font-size: 11px;
        line-height: 14px;
        color: #2f7fed;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;  
        overflow: hidden;
    }
    .chat_box .input_area .media_area i{
        position: absolute;
        right: 8px;
        top: 3px;
        padding: 0;
    }

    .msg .img-thumbnail {
        width: auto;
        height: 100px;
        border-radius: 10px;
        object-fit: cover;
        margin-left: 10px;
    }

    .media_view {
        margin-top: 10px;
    }

    :deep(.media_view video) {
        width: 200px;
    }

    :deep(.media_view img) {
        width: auto;
        height: 100px;
        border-radius: 10px;
        object-fit: cover;
        margin-left: 10px;
    }

    .infinite-loading {
        margin-top: 0;
        margin-bottom: 30px;
    }

    .msg span.text-danger {
        color: #eb1414;
    }

    .msg p {
        word-break: break-word !important;
    }
</style>
